import React from "react";

import { useLazyQuery } from "@apollo/client";
import { Button, Skeleton, Typography } from "@mui/material";
import moment from "moment";

import Empty from "../../Components/EmptyData/Empty";
import { BASE_URL } from "../../Config/constant";
// import { BASE_URL } from "../../Config/constant";
import { Query } from "../../graphql/__generated__/types";
import { GET_ALL_FACTURES } from "../../graphql/factures/query";
import { useStyles } from "./style";

const Factures: React.FC = () => {
  const style = useStyles();

  const [getFactures, { data, loading }] = useLazyQuery<Query>(
    GET_ALL_FACTURES,
    {
      fetchPolicy: "cache-first",
    },
  );

  React.useEffect(() => {
    getFactures();
  }, [getFactures]);

  return (
    <div className={style.container}>
      {!loading &&
        data?.GetListFactures?.map((item, index) => {
          return (
            <div
              className={style.containerCard}
              style={{
                background: index % 2 === 0 ? "#F2F2F2" : "#fff",
                borderTopLeftRadius: index === 0 ? 21 : undefined,
              }}
            >
              <div
                style={{
                  flex: "1 1 0%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Typography>
                  {moment(item?.date_facture).format("Do MMM YYYY")}
                </Typography>
                <Typography>{`n°${item?.nom_facture}`}</Typography>
              </div>
              <div style={{ width: "15%" }}>
                {item?.url && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      // window.open(item?.url || "", "_blank");
                      window.open(
                        `${BASE_URL}/#/dashboard/facture/${item.id}/view` || "",
                        "_blank",
                      );
                    }}
                  >
                    Voir
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      {loading && (
        <>
          <Skeleton width="100%" height={100} />
          <Skeleton width="100%" height={100} />
          <Skeleton width="100%" height={100} />
        </>
      )}
      {!loading && !data?.GetListFactures?.length && (
        <div
          style={{
            width: "100%",
            paddingTop: "10px",
            height: "100%",
          }}
        >
          <Empty />
        </div>
      )}
    </div>
  );
};

export default Factures;
